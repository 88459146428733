import './App.css';
import { ThemeProvider } from '@primer/react'
import Homepage from './content/Homepage/Homepage';

function App() {





  return (
    <>
      <ThemeProvider >
        <Homepage />
      </ThemeProvider>

    </>
  );
}

export default App;
